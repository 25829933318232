import React, { useState, useEffect } from 'react';
import Footer from './footer.js';
import { Link } from 'react-router-dom';
const axios = require('axios');


const Home = () =>{

    //
    const [cover, setCover] = useState("");
    const [coverName, setCoverName] = useState("");
    const [coverIm, setCoverIm] = useState("");
    const [coverTitle, setCoverTitle] = useState("");
   

    //
    useEffect(() => {
        callCover();
        callTitle();
    }, []);
    
    //
    async function callCover(){
        try{
            const response = await axios.get('https://api.vimeo.com/users/2185830/albums/8689807/videos', {
                headers: {
                  Authorization: `Bearer 4d4cb0e36abddc0da43d32c2480d1711`
                },
            })
            var d = response.data.data;
            setCover(d[0].uri);
            setCoverName(d[0].name);
            setCoverIm("url" + "("  + "'" + d[0].pictures.sizes[6].link + "'" + ")");
            //console.log(cover);
        }catch(error){
            console.log(error);
        }	   
    }

    //
    async function callTitle(){
        try{
            const response = await axios.get('https://api.vimeo.com/users/2185830/albums/8689807', {
                headers: {
                  Authorization: `Bearer 4d4cb0e36abddc0da43d32c2480d1711`
                },
            })
            var x = response.data;
            //console.log(x.description);
            setCoverTitle(x.description);
        }catch(error){
            console.log(error);
        }	   
    }

    
    return(

        <div className="cover_main">

            {
                cover === ""
            ?   
            
            <div className="load_screen">LOADING...</div>

            :
            <div className="cover" style={{backgroundImage: coverIm}}>
                <div className="filtercolor2"></div>
                <div className="cover_info">
                    <h1 className="covertitle">{coverTitle}</h1>
                    <Link className="actionbtn" to="/work">EXPLORE OUR WORK</Link>
                    <div className="cover_preview">
                        <p className="coverproject">COVER PROJECT: {coverName}</p>
                        <Link  className="actionbtn" to={cover}>VIEW PROJECT</Link>
                    </div>
                </div>
                <div className="foot">
                    <Footer/>
                </div>
            </div>
            }
        </div>   
    )
}

export default Home;