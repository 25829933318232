import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from './footer.js';
//import imgs from './interactive_stills.json';

const axios = require('axios');

const Interactive = () =>{

    const [imgpop, setImgpop] = useState('');
    const [imgs, setImgs] = useState(JSON.parse(window.sessionStorage.getItem('mimgs')));
    
    function closePop(){
        document.getElementById('popup').style.display = "none";
    }

    function openPop(a){
        document.getElementById('popup').style.display = "flex";
        setImgpop(a);
    }

    //
    async function loadList(){
        try{
            const response = await axios.get('https://renderplayz.com/api/interactive_api.json');
            var d = response.data;
            try{
                window.sessionStorage.setItem('mimgs', JSON.stringify(d));
                setImgs(JSON.parse(window.sessionStorage.getItem('mimgs')));
                console.log(imgs);
            }catch(error){
                console.log(error);
            }
            
        }catch(error){
            console.log(error);
        }	   
    }
   

    //
    useEffect(() => {
        if(imgs === null){
            loadList();
        }else{
            console.log("lista de imágenes cargada");
        }

    }, []);
    
   

    
    return(
        <div className="container">
            
            <div className="popup" id="popup">
                <div className="closepop" onClick={closePop}>&times;</div>
                <div className="popImg">
                    <img src={imgpop} alt="Cover"/>
                </div> 
            </div>

            {
                imgs === null
                ?   
                <div className="load_screen2">LOADING...</div>
                :
                <div>

                    <div className="title">
                        <h1 className="section_titles">APPS & WEB</h1>
                    </div>

                    <div className="main_list" id="motion_list">
                        {imgs.map((img, index) => (
                            <div className="pro_main" key={index}>
                                <div className="pro_margin">
                                    <img onClick={() => openPop(img.link)} className="pro_image2" src={img.link} alt="Cover"/>
                                    <p className="pro_title">{img.name}</p>
                                    <p className="pro_client">Client: {img.client}</p>
                                    {
                                        img.go == ''
                                        ?
                                        <a className="pro_link2" href={img.go} target="_blank">VIEW PROJECT</a> 
                                        :
                                        <a className="pro_link" href={img.go} target="_blank">VIEW PROJECT</a> 
                                    }
                                </div>  
                            </div>
                        ))}
                        <div className="clear"></div>
                    </div>
                    <Footer/>
                </div>
            }

        </div>
    )

}

export default Interactive;