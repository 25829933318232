import React, { useState, useEffect } from 'react';
import Footer from './footer.js';
import Submenu from './submenu.js';
import { Link } from 'react-router-dom';
const axios = require('axios');


const Motion = () =>{

    //
    const [list, setList] =  useState(JSON.parse(window.sessionStorage.getItem('mvids')));

    //
    useEffect(() => {
       if(list === null){
        loadLists();
       }else{
           console.log("lista de videos cargada");
       }

    }, []);
    
    //
    async function listMotion(){
        try{
            const response = await axios.get('https://api.vimeo.com/users/2185830/albums/8591699/videos?per_page=30', {
                headers: {
                  Authorization: `Bearer 4d4cb0e36abddc0da43d32c2480d1711`
                },
            })
            var d = response.data.data;
            try{
                window.sessionStorage.setItem('mvids', JSON.stringify(d));
                setList(JSON.parse(window.sessionStorage.getItem('mvids')));
                //console.log(list);
            }catch(error){
                console.log(error);
            }
        }catch(error){
            console.log(error);
        }	   
    }


    //
    function loadLists(){
        listMotion();
    }
    
    return(
        <div className="container">

            {
                list === null
            ?   
            <div className="load_screen2">LOADING...</div>
            :
            <div>
                
                <div className="title">
                    <h1 className="section_titles">PRODUCTION & POST-PRODUCTION</h1>
                </div>

                <div className="main_list" id="motion_list">
                    {list.map(vid => (
                        <div className="pro_main" key={vid.resource_key}>
                            <div className="pro_margin">
                                <img className="pro_image" src={vid.pictures.sizes[6].link} alt="Cover"/>
                                <p className="pro_title" >{vid.name}</p>  
                                <Link className="pro_link" to={vid.uri}>VIEW PROJECT</Link> 
                            </div>  
                        </div>
                    ))}
                    <div className="clear"></div>
                </div>

                <Footer/>
            </div>
            }
        </div>
    )

}

export default Motion;