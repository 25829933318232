import React, { useState, useEffect } from 'react';
import Footer from './footer.js';
import Submenu from './submenu.js';
import { Link } from 'react-router-dom';
const axios = require('axios');


const Work = () =>{
   
    
    return(
        <div className="container">

        

            <div className="work_links">
                <div className="work_main">
                    <div className="title">
                        <h1 className="section_titles">Explore our work</h1>
                    </div>
                    <div className="work_boxes">
                        <Link to="/motion" className="work_link">PRODUCTION & POST-PRODUCTION</Link>
                        <Link to="/interactive" className="work_link">MOBILE APPS & WEB DEVELOPMENT</Link>
                        <Link to="/logorimetria" className="work_link">LOGORIMETRÍA</Link>
                    </div>
                </div>
               
            </div>

           
            <div className="foot">
                <Footer/>
            </div>
           
        </div>
    )

}

export default Work;