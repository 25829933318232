import React, { useState, useEffect } from 'react';
import Footer from './footer.js';
import Submenu from './submenu.js';
import { Link } from 'react-router-dom';
const axios = require('axios');


const Logorimetria = () =>{

    //
    const [list, setList] =  useState(JSON.parse(window.sessionStorage.getItem('iclips')));
    const feed = (process.env.REACT_APP_INSTATOKEN);

    //
    const [imgpop, setImgpop] = useState('');

    //
    useEffect(() => {
       if(list === null){
        loadList();
       }else{
           console.log("lista de Instagram cargada");
       }

    }, []);
    

     //
     async function loadList(){
        try{
            const response = await axios.get(`https://graph.instagram.com/me/media?fields=media_url,children,caption&limit=42&access_token=${feed}`);
            var d = response.data.data;
            try{
                window.sessionStorage.setItem('iclips', JSON.stringify(d));
                setList(JSON.parse(window.sessionStorage.getItem('iclips')));
                console.log(list);
            }catch(error){
                console.log(error);
            }
            
        }catch(error){
            console.log(error);
        }	   
    }

    //
    function closePop(){
        document.getElementById('popup').style.display = "none";
        setImgpop('');
    }

    //
    async function itemInsta(a){
        try{
            const response = await axios.get(`https://graph.instagram.com/${a}?fields=media_type,media_url&access_token=${feed}`);
            console.log(response.data);
            setImgpop(response.data.media_url);
            console.log(imgpop);
            document.getElementById('popup').style.display = "flex";
        }catch(error){
            console.log(error);
        }	   
    }




    //
    function loadLists(){
        //listMotion();
    }
    
    return(
        <div className="container">
            
            <div className="popup" id="popup">
                <div className="closepop" onClick={closePop}>&times;</div>
                <div className="popVid">
                    {
                        imgpop != ''
                        ?
                        <div className="popframe">
                            <iframe src={imgpop} width="100%" height="auto" frameBorder="0" allowFullScreen></iframe>
                        </div>
                        :
                        <p className="err_screen">Try again.</p>

                    }

                </div> 
            </div>
            
            {
                list === null
            ?   
            <div className="load_screen2">LOADING...</div>
            :
            <div>
                
                <div className="title">
                    <h1 className="section_titles">LOGORIMETRÍA</h1>
                </div>

                <div className="main_list" id="motion_list">
                    {list.map(ins => (
                        <div className="pro_main" key={ins.id} onClick={() => itemInsta(ins.children.data[0].id)}>
                            <div className="pro_margin">
                                <img className="pro_image2" src={ins.media_url} alt="Image"/>
                            </div>  
                        </div>
                    ))}
                    <div className="clear"></div>
                </div>

                <Footer/>
            </div>
            }
        </div>
    )

}

export default Logorimetria;