import React, { useState, useEffect } from 'react';



const Footer = () => {

    //
    const [year, setYear] =  useState('');
    
    //
    useEffect(() => {
       if(year == ''){
        var x = new Date;
        setYear(x.getFullYear());
       }else{
           console.log("Fecha cargada")
       }
     }, []);
    
    return (
        <div className="close">
           <p>Made by Render Playz<br/>All rights reserved <span>&copy;</span> {year}</p>
        </div>
    )

}

export default Footer;