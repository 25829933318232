import React, { useState, useEffect } from 'react';
import Footer from './footer.js';

import insta from './images/instagram.png';
import vimeo from './images/vimeo.png';

const Contact = () =>{

    return(

    <div>
    
        <div className="cover">
            <div className="info_contact">
                <h1 className="section_titles">Let's start a new project together</h1>
                <p>Worldwide - Monterrey, NL. México</p>
                <a className="linkmail" href="mailto:hola@renderplayz.com">hola@renderplayz.com</a>
                <div className="redes">
                    <a href="https://www.instagram.com/renderplayz/" target="_blank"><img src={insta} alt="Instagram"/></a>
                    <a href="https://vimeo.com/renderplayz" target="_blank"><img src={vimeo} alt="Vimeo"/></a>
                </div>
            </div>
            <div className="foot">
                <Footer/>
            </div>
        </div>

    </div>
        
    )


}

export default Contact;