import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
const axios = require('axios');



const Video = () => {
    //
    const u = window.location.pathname;
    //console.log(u);
    var res3 = u.substr(8);
    //console.log(res3);

    //
    const [URL, setURL] = useState();
    const [URL2, setURL2] = useState([]);
    const [backIm, setBackIm] = useState();

    //
    useEffect(() => {
        callUrl();
        callUrl2();
      }, []);

    //
      function callUrl(){
        setURL("https://player.vimeo.com/video/" + res3);
        //console.log(URL);
    }

    //
    async function callUrl2(){
        try{
            const response = await axios.get('https://api.vimeo.com/videos/' + res3, {
                headers: {
                  Authorization: `Bearer 4d4cb0e36abddc0da43d32c2480d1711`
                },
            })
            var d = response.data;
            //console.log(d);
            setURL2(d);
            setBackIm("url" + "("  + "'" + d.pictures.sizes[6].link + "'" + ")");
        }catch(error){
            console.log(error);
        }	   
    }


    return (
        <div className="project_main">

            {
                backIm ===""
            ?   
            <div className="load_screen">LOADING...</div>
            :
            <div>
                <div className="back_video" style={{backgroundImage: backIm}}></div>
                <div className="filtercolor"></div>
                <div className="project">
                    <Link className="backbtn" to="/motion"> &larr; ALL PROJECTS</Link>
                    <div className="frame">
                        <iframe src={URL} width="100%" height="auto" frameBorder="0" allowFullScreen></iframe>
                    </div>
                    <h1>{URL2.name}</h1>
                    <p style={{whiteSpace: "pre-wrap"}}>{URL2.description}</p>
                </div>
            </div>
            }
        </div>
    );
}

export default Video;