import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import logo1 from './images/logo.png';
import logo2 from './images/isotipo.png';

const Menu = () => {

    return (
        <div className="nav">
                <Link to="/"><div className="logo">
 					<img src={logo1} alt="Render Playz"/>
 				</div></Link>

                <Link to="/"><div className="logo_min">
                    <img src={logo2} alt="Render Playz"/>
 				</div></Link>

                 <div className="menu">
                    <NavLink className="top_link" activeClassName="menu_selected" to="/" end>HOME</NavLink>
                    <NavLink className="top_link" activeClassName="menu_selected" to="/work">WORK</NavLink>
                    <NavLink className="top_link" activeClassName="menu_selected" to="/contact">CONTACT</NavLink>
    
 				</div>
        </div>
    )

}

export default Menu;