import React from 'react';
import { Routes, Route } from 'react-router';
import { Helmet } from "react-helmet";

import './assets/styles.css';

import Home from './assets/home.js';
import Work from './assets/work.js';
import Motion from './assets/motion.js';
import Interactive from './assets/interactive.js';
import Logorimetria from './assets/logorimetria.js';
import Video from './assets/video.js';
import Contact from './assets/contact.js';

import Menu from './assets/menu.js';


function App() {
    return (
      <div>
        <Helmet>
          <meta charset="utf-8"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Render Playz</title>
          <meta name="title" content="Render Playz"/>
          <meta name="description" content="Render Playz es un estudio creativo de animación, diseño, producción audiovisual y desarrollo de apps."/>
          <meta property="og:url" content='http://renderplayz.com/' />
          <meta property="og:title" content="Render Playz" />
          <meta property='og:description' content='Render Playz es un estudio creativo de animación, diseño, producción audiovisual y desarrollo de apps.'/>
          <meta property="og:image" content='./assets/images/cover.png'/>
          <meta property="og:type" content="website" /> 
        </Helmet>

        <Menu/>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route path="/work" element={<Work/>}/>
          <Route path="/interactive" element={<Interactive/>}/>
          <Route path="/logorimetria" element={<Logorimetria/>}/>
          <Route path="/motion" element={<Motion/>}/>
          <Route path="/videos/:id" element={<Video/>}/>
          <Route path="/contact" element={<Contact/>}/>
        </Routes>
      </div>
    );
}

export default App;
